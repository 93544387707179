import React, { Component } from "react";
import InfoDialogFooterViewStyle from "./InfoDialogFooterViewStyle";

export default class InfoDialogFooterView extends Component {

    private _style = new InfoDialogFooterViewStyle();

    render() {
        return (
            <div style={this._style.container}>
                <img style={this._style.logo} src={require('../../../../../assets/small_logo.png')} alt='Medicheck logo' />
                <div style={this._style.border} />
            </div>
        );  
    }
}
import React, { Component } from "react";
import DialogBaseView from "../../../../components/DialogBase/DialogBaseView";
import GSMProblemDialogViewProps from './GSMProblemDialogViewProps';
import GSMProblemDialogViewState from "./GSMProblemDialogViewState";
import GSMProblemDialogPhoneNumberView from "./components/GSMProblemDialogPhoneNumberView/GSMProblemDialogPhoneNumberView";
import GSMProblemDialogActionView from "./components/GSMProblemDialogActionView/GSMProblemDialogActionView";

export default class GSMProblemDialogView extends Component<GSMProblemDialogViewProps, GSMProblemDialogViewState> {

    state = {
        gsmNumber: '',
        selectedAction: undefined,
    };

    render() {
        const { errors, hasPhoneNumber } = this.props;

        return (
            <DialogBaseView title='GSMProblem' subtitle='GSMTurnedOff' onSubmit={this.onSubmit} error={errors.form} hasLeftButton={hasPhoneNumber} onSubmitLeftButton={this.onSubmitLeftButton} titleLeftButton='noOtherGSMNumberButton' disabled={this.onDisable()}>
                {hasPhoneNumber ? (
                    <GSMProblemDialogPhoneNumberView errors={errors} onGSMNumberChange={this.onGSMNumberChange}/>
                ) : (
                    <GSMProblemDialogActionView errors={errors} onActionChange={this.onActionChange} selectedAction={this.state.selectedAction} />
                )}
            </DialogBaseView>
        );
    }

    onGSMNumberChange = (text: string) => {
        this.setState({
            gsmNumber: text
        });
    }

    onActionChange = (value: number) => {
        this.setState({
            selectedAction: value
        });
    }

    onSubmit = (done: () => void) => {
        this.props.onSubmit(this.state, done);
    }

    onSubmitLeftButton = (done: () => void) => {
        this.props.onHasPhoneNumberChange(false);
        this.props.resetErrors();
        done();
    }

    onDisable = () => {
        return this.props.onDisable(this.state);
    }
}
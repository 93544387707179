import { CSSProperties } from "@material-ui/styles";
import Colors from "../../../../../constants/Colors";

export default class InfoDialogBaseViewStyle {
    container: CSSProperties = {
        minHeight: 300,
        height: 'fit-content',
        width: 600,
        backgroundColor: Colors.Primary,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column"
    }

    mobileContainer: CSSProperties = {
        minHeight: 300,
        height: 'fit-content',
        width: "95%",
        backgroundColor: Colors.Primary,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        margin: 'auto',
        marginTop: 116,
    }

    content: CSSProperties = {
        display: "flex",
        flex: 1,
        padding: 30,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center"
    }
}
import React, { Component } from "react";
import DialogErrorTextViewStyle from "./DialogErrorTextViewStyle";

export default class DialogErrorTextView extends Component {

    private _style = new DialogErrorTextViewStyle();

    render() {
        return (
            <span style={this._style.text}>
                {this.props.children}
            </span>
        );
    }
}
import React, { Component } from "react";
import DialogHeaderViewStyle from "./DialogHeaderViewStyle";
import DialogHeaderViewProps from "./DialogHeaderViewProps";
import TranslationView from "../../../../../../components/translation/TranslationView";
import { isMobile } from "react-device-detect";

export default class DialogHeaderView extends Component<DialogHeaderViewProps> {

    private _style = new DialogHeaderViewStyle();

    render() {
        console.log('isMobile', isMobile);
        
        return (
            <div style={isMobile ? this._style.mobileHeader : this._style.header}>
                <div style={isMobile ? this._style.mobileHeaderTextContainer  : this._style.headerTextContainer}>
                    <TranslationView style={this._style.title}>
                        {this.props.title}
                    </TranslationView>

                    <TranslationView style={isMobile ? this._style.mobileSubtitle : this._style.subtitle}>
                        {this.props.subtitle}
                    </TranslationView>
                </div>
                <img style={isMobile ? this._style.mobileSmallLogo : this._style.smallLogo} src={require('../../../../../../assets/small_logo.png')} alt="The Medicheck logo" />
            </div>
        );
    }
}
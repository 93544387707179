import React, { Component, ChangeEvent } from "react";
import DialogTextInputViewProps from './DialogMultilineTextInputViewProps';
import DialogTextInputViewStyle from './DialogMultilineTextInputViewStyle';
import { TextField } from "@material-ui/core";
import TranslationView from "../../../../../../components/translation/TranslationView";
import { isMobile } from "react-device-detect";

export default class DialogMultilineTextInputView extends Component<DialogTextInputViewProps> {

    private _style = new DialogTextInputViewStyle();

    render() {
        const { placeholder, rows } = this.props;

        return (
            <TextField
                style={ isMobile ? this._style.mobileTextField : this._style.textField}
                inputProps={{ style: this._style.input }}
                label={placeholder && TranslationView.translate(placeholder)}
                onChange={this.onChange}
                multiline
                rows={rows}
                variant="outlined" />
        );
    }

    onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange && this.props.onChange(e.target.value);
    }
}
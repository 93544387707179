import { CSSProperties } from "react";
import FontWeight from "../../../../../../constants/FontWeight";

export default class InfoDialogTitleViewStyle {

    title: CSSProperties = {
        fontFamily: 'Asap',
        fontWeight: FontWeight.REGULAR,
        color: '#fff',
        fontSize: 18,
        marginBottom: 5
    }

    mobileTitle: CSSProperties = {
        fontFamily: 'Roboto',
        fontWeight: FontWeight.LIGHT,
        color: '#fff',
        fontSize: 15,
        marginBottom: 5,
        lineHeight: "17.58px"
    }
}
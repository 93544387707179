import React, { Component } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DialogTimePickerViewProps from "./DialogTimePickerViewProps";
import { parse } from "date-fns/esm";

export default class DialogTimePickerView extends Component<DialogTimePickerViewProps> {

    render() {

        const { value, error, onChange } = this.props;

        const shownValue = value ? parse(value, "kk:mm", new Date(), { locale: this.context.locale }) : null;

        console.log(value);

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    autoOk
                    ampm={false}
                    error={!!error}
                    value={shownValue}
                    disableToolbar={true}
                    placeholder='hh:mm'
                    format="kk:mm"
                    onChange={(date, value) => { onChange({ target: { value: value } }); console.log(date); }}
                    FormHelperTextProps={{ style: { display: 'none' } }} />

                <p className='error'>
                    {error && this.context.translate &&
                        this.context.translate(error!)
                    }
                </p>
            </MuiPickersUtilsProvider>
        );
    }
}
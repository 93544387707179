import { CSSProperties } from "react"
import Colors from "../../../../../../../constants/Colors"
import FontWeight from "../../../../../../../constants/FontWeight"

export default class DialogHeaderViewStyle {

    header: CSSProperties = {
        maxHeight: 85,
        backgroundColor: Colors.Primary,
        display: "flex"
    }

    mobileHeader: CSSProperties = {
        maxHeight: 85,
        backgroundColor: Colors.Primary,
        display: "flex",
        flex: 1,
        width: "100%"
    }

    headerTextContainer: CSSProperties = {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        paddingLeft: 30,
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 15
    }

    mobileHeaderTextContainer: CSSProperties = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        paddingLeft: 30,
        paddingTop: 15,
        paddingRight: 15,
        paddingBottom: 15
    }

    title: CSSProperties = {
        color: '#fff',
        fontFamily: "Roboto",
        fontWeight: FontWeight.LIGHT,
        marginBottom: 0,
        fontSize: 15
    }

    subtitle: CSSProperties = {
        color: "#fff",
        fontFamily: "Asap",
        fontWeight: FontWeight.MEDIUM,
        fontSize: 22
    }

    mobileSubtitle: CSSProperties = {
        color: "#fff",
        fontFamily: "Asap",
        fontWeight: FontWeight.MEDIUM,
        fontSize: 18
    }

    smallLogo: CSSProperties = {
        height: 50,
        paddingLeft: 20,
        paddingRight: 20,
        alignSelf: "flex-end"
    }

    mobileSmallLogo: CSSProperties = {
        display: "none"
    }
}
import { CSSProperties } from "react";

export default class DialogBaseViewStyle {

    container: CSSProperties = {
        width: 570,
        borderRadius: 15,
        backgroundColor: '#fff',
        overflow: 'hidden',
        display: "flex",
        flexDirection: 'column'
    }

    mobileContainer: CSSProperties = {
        height: 'fit-content',
        width: "95%",
        borderRadius: 15,
        backgroundColor: '#fff',
        overflow: 'hidden',
        display: "flex",
        flex: 1,
        flexDirection: 'column',
        margin: 'auto',
        marginTop: '38px'
    }

    content: CSSProperties = {
        flex: 1,
        paddingTop: 20,
        paddingRight: 35,
        paddingLeft: 35,
        paddingBottom: 5,
        display: "flex",
        flexDirection: "column",
    }
}
import React, { Component } from "react";
import DialogBaseView from "../../../../components/DialogBase/DialogBaseView";
import DialogPromptView from "../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DenyAvailabilityDialogViewProps from './DenyAvailabilityDialogViewProps';
import DialogMultilineTextInputView from "../../../../components/DialogBase/components/DialogMultilineTextInput/DialogMultilineTextInputView";
import DenyAvailablilityDialogViewState from "./DenyAvailabilityDialogViewState";
import DenyAvailabilityDialogViewStyle from "../DenyAvailabilityDialogViewStyle";
import { ReadableDate } from "../../../../../../components/readableDate/ReadableDate";

export default class DenyAvailabilityDialogView extends Component<DenyAvailabilityDialogViewProps, DenyAvailablilityDialogViewState> {

    style = new DenyAvailabilityDialogViewStyle();

    state = {
        comment: ''
    };

    render() {
        const { error, checkDate } = this.props;

        return (
            <DialogBaseView title='bookAppointment' subtitle='noAvailability' onSubmit={this.onSubmit} error={error}>
                <DialogPromptView>
                    <TranslationView style={{ display: "block" }}>
                        doctorGreeting
                    </TranslationView>
                    <div style={{ marginTop: 10 }}>
                        <TranslationView>
                            pressSendNoAvailabilityStart
                        </TranslationView>

                        <span style={this.style.date}>
                            <ReadableDate date={Date.parse(checkDate)} />
                        </span>

                        <TranslationView>
                            pressSendNoAvailabilityEnd
                        </TranslationView>
                    </div>
                </DialogPromptView>

                <div style={{ marginTop: 15 }}>
                    <DialogMultilineTextInputView
                        rows={4}
                        placeholder='addComment'
                        onChange={this.onComment} />
                </div>
            </DialogBaseView>
        );
    }

    onSubmit = (done: () => void) => {
        this.props.onSubmit(this.state, done);
    }

    onComment = (comment: string) => {
        this.setState({ comment });
    }
}
import React, { Component } from "react";
import DialogTextInputView from "../../../../../../components/DialogBase/components/DialogTextInput/DialogTextInputView";
import GSMProblemDialogPhoneNumberViewProps from './GSMProblemDialogPhoneNumberViewProps';
import TranslationView from "../../../../../../../../components/translation/TranslationView";
import DialogPromptView from "../../../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";

export default class GSMProblemDialogPhoneNumberView extends Component<GSMProblemDialogPhoneNumberViewProps> {

    render() {
        const { errors, onGSMNumberChange } = this.props;

        return (
            <div>
                <DialogPromptView style={{ marginBottom: 20 }}>
                    <TranslationView>
                        pleaseUpdateGSMNumber
                    </TranslationView>
                </DialogPromptView>

                <DialogTextInputView
                    style={{  marginBottom: 30 }}
                    placeholder='GSMNumber'
                    onChange={onGSMNumberChange}
                    error={errors.gsmNumber} 
                />
            </div>
        );
    }
}
import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import TelecheckSwitchDialogView from "./components/TelecheckSwitchDialog/TelecheckSwitchDialogView";
import TelecheckSwitchDialogViewState from "./components/TelecheckSwitchDialog/TelecheckSwitchDialogViewState";
import Service from "../../../../../network/Service";
import Application from "../../../../Application";

export default class TelecheckSwitchView extends Component<SharedViewProps> {

    service = new Service();

    state = {
        error: undefined,
        queryParams: new URLSearchParams(this.props.routeProps.location.search)
    };

    render() {
        return (
            <TelecheckSwitchDialogView onSubmit={this.onSubmit} error={this.state.error} />
        );
    }

    onSubmit = async (formData: TelecheckSwitchDialogViewState, done: () => void) => {
        const checkId = this.state.queryParams.get('checkId');
        const superiorId = this.state.queryParams.get('superiorId');

        if (checkId && superiorId) {
            this.setState({ error: undefined });
            const { error } = await this.service.switchTelecheck(checkId, {
                superiorId: superiorId,
                comment: formData.comment
            });

            if (!error) {
                this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                    origin: 'switch-telecheck'
                });
            }

            else {
                this.setState({ error: 'genericError' });
                done();
            }
        }

        else {
            this.setState({ error: 'genericError' });
            done();
        }
    }
}
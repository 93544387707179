import { CSSProperties } from "@material-ui/styles";
import Colors from "../../../../../../constants/Colors";

export default class InfoDialogFooterViewStyle {

    container : CSSProperties = {
        width : '100%',
        borderBottomLeftRadius : 10,
        borderBottomRightRadius : 10,
        backgroundColor : Colors.Primary,
        display : 'flex',
        flexDirection : 'column',
        overflow : 'hidden'
    }

    logo : CSSProperties = {
        height : 50,
        marginRight : 30,
        alignSelf : 'flex-end'
    }

    border : CSSProperties = {
        height : 15,
        width : '100%',
        backgroundColor : '#fff'
    }
}
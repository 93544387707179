import React, { Component, ChangeEvent } from "react";
import { Select, InputLabel, FormControl, FormHelperText } from "@material-ui/core";
import DialogSelectViewProps from "./DialogSelectViewProps";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DialogSelectViewStyle from './DialogSelectViewStyle';

export default class DialogSelectView extends Component<DialogSelectViewProps> {

    private _style = new DialogSelectViewStyle();

    render() {
        return (
            <FormControl style={this._style.control} error={this.props.error !== undefined}>
                <InputLabel style={this._style.placeholder} htmlFor="select">
                    <TranslationView>
                        {this.props.placeholder}
                    </TranslationView>
                </InputLabel>

                <Select onChange={this.onChange} value={this.props.value} inputProps={{  id : 'select' }}>
                    {this.props.children}
                </Select>
                
                {this.props.error &&
                    <FormHelperText>
                        <TranslationView>
                            {this.props.error}
                        </TranslationView>
                    </FormHelperText>
                }
            </FormControl>
        );
    }

    onChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        this.props.onChange && this.props.onChange(event.target.value, event.target.name);
    }
}
import React, { Component } from "react";
import ConfirmAvailabilityHomeDialogView from "./modules/Home/ConfirmAvailabilityHomeDialogView";
import ConfirmAvailabilityOfficeDialogView from "./modules/Office/ConfirmAvailabilityOfficeDialogView";
import ConfirmAvailabilityDialogViewState from "./modules/Office/ConfirmAvailabilityOfficeDialogViewState";
import ConfirmAvailabilityViewState from './ConfirmAvailabilityViewState';
import SharedViewProps from "../../../../shared/SharedViewProps";
import Service from "../../../../../network/Service";
import { Switch, Route } from "react-router-dom";
import Application from "../../../../Application";

export default class ConfirmAvailabilityView extends Component<SharedViewProps, ConfirmAvailabilityViewState> {

    // ATTRIBUTES
    service = new Service();
    state = {
        error: undefined,
        queryParams: new URLSearchParams(this.props.routeProps.location.search)
    }

    // RENDER
    render() {
        const { match } = this.props.routeProps;
        const { error, queryParams } = this.state;
        const checkDate = queryParams.get('checkDate');
        const checkTimeOfDay = queryParams.get('checkTimeOfDay');
        const checkTimeStart = queryParams.get('checkTimeStart');
        const checkTimeEnd = queryParams.get('checkTimeEnd');

        // Initial state, display form
        if (checkDate) {
            return (
                <Switch>
                    <Route path={`${match.url}/home`} render={() => <ConfirmAvailabilityHomeDialogView error={error} checkTimeEnd={checkTimeEnd} checkTimeStart={checkTimeStart} checkTimeOfDay={checkTimeOfDay} checkDate={checkDate} onSubmit={this.onSubmit} />} />
                    <Route path={`${match.url}/office`} render={() => <ConfirmAvailabilityOfficeDialogView error={error} checkTimeEnd={checkTimeEnd} checkTimeStart={checkTimeStart} checkTimeOfDay={checkTimeOfDay} checkDate={checkDate} onSubmit={this.onSubmit} />} />
                </Switch>
            );
        }

        // Unexpected state, checkDate possibly not in query params
        else {
            return (
                <p>Erreur</p>
            );
        }
    }

    // HANDLERS
    onSubmit = async (formData: ConfirmAvailabilityDialogViewState, done: () => void) => {
        const checkId = this.state.queryParams.get('checkId');
        const doctorId = this.state.queryParams.get('doctorId');
        const checkDate = this.state.queryParams.get('checkDate');

        if (checkId && doctorId && checkDate && formData.selectedTime) {
            const { error } = await this.service.confirmAvailability(checkId, {
                checkDate: checkDate,
                checkTime: formData.selectedTime,
                doctorId: doctorId,
                doctorComment: formData.comment
            });

            if (!error) {
                this.setState({
                    error: undefined
                });

                const checkDate = this.state.queryParams.get('checkDate');
                this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                    origin: 'confirm-availability',
                    checkDate: checkDate,
                    checkTime: formData.selectedTime
                });
            }

            else {
                if(formData.selectedTime)
                {
                    this.props.routeProps.history.push(`/${Application.language}/forms/already-booked-check`, {
                        origin: 'confirm-availability'
                    });
                }
                else
                {
                    this.setState({
                        error: 'errorRequiredField'
                    });
                }
            }

            done();
        }
    }
}
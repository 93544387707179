import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import InfoDialogTextViewStyle from "./InfoDialogTextViewStyle";

export default class InfoDialogTextView extends Component {

    private _style = new InfoDialogTextViewStyle();

    render() {
        return (
            <span style={isMobile ? this._style.mobileText : this._style.text}>
                {this.props.children}
            </span>
        );
    }
}
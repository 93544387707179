import React, { Component } from "react";
import DialogBaseView from '../../../../components/DialogBase/DialogBaseView';
import DialogPromptView from "../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DialogMultilineTextInputView from "../../../../components/DialogBase/components/DialogMultilineTextInput/DialogMultilineTextInputView";
import RelaunchCheckDialogProps from "./props";
import RelaunchCheckDialogState from "./state";

export default class RelaunchCheckDialogView extends Component<RelaunchCheckDialogProps, RelaunchCheckDialogState> {

    state = {
        comment: ''
    };

    render() {
        return (
            <DialogBaseView title='relaunchCheck' subtitle='relaunchCheckSubTitle' onSubmit={this.onSubmit} error={this.props.error}>
                <DialogPromptView>
                    <TranslationView>
                        pressSendToRelaunch
                    </TranslationView>
                </DialogPromptView>

                <DialogMultilineTextInputView onChange={this.onChange} rows={4} placeholder='addComment' />
            </DialogBaseView>
        );
    }

    onSubmit = (done: () => void) => {
        this.props.onSubmit(this.state, done);
    }

    onChange = (comment: string) => {
        this.setState({
            comment: comment
        });
    }
}
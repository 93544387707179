import React, { Component } from "react";
import InfoDialogBaseView from "../../../../components/InfoDialogBase/InfoDialogBaseView";
import InfoDialogTitleView from "../../../../components/InfoDialogBase/InfoDialogTitle/InfoDialogTitleView";
import InfoDialogSubtitleView from "../../../../components/InfoDialogBase/InfoDialogSubtitle/InfoDialogSubtitleView";
import InfoDialogTextView from "../../../../components/InfoDialogBase/InfoDialogText/InfoDialogTextView";
import TranslationView from "../../../../../../components/translation/TranslationView";

export default class GSMProblemConfirmationView extends Component {

    render() {
        return (
            <InfoDialogBaseView>
                <InfoDialogTitleView>
                    <TranslationView style={{ fontWeight: 300 }}>
                        GSMProblem
                    </TranslationView>
                </InfoDialogTitleView>

                <InfoDialogSubtitleView>
                    <TranslationView style={{ fontWeight: 500 }}>
                        solutionSent
                    </TranslationView>
                </InfoDialogSubtitleView>

                <InfoDialogTextView>
                    <TranslationView style={{ fontWeight: 300 }}>
                        weWillResendSMSOrFollowAction
                    </TranslationView>
                </InfoDialogTextView>
            </InfoDialogBaseView>
        );
    }
}
export default class FooterViewStyle {
    madeByMediCheck: React.CSSProperties = {
        position: 'absolute',
        width: '100%',
        bottom: 7.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 30,
        fontSize: 13,
        left: 0,
        color: "white",
        backgroundColor: 'transparent'
    }

    mobileMadeByMediCheck: React.CSSProperties = {
        // position: 'absolute',
        width: '100%',
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginBottom: 15,
        height: 30,
        fontSize: 13,
        left: 0,
        color: "white",
        backgroundColor: 'transparent'
    }

    privacyPolicy: React.CSSProperties = {
        cursor: 'pointer',
        fontSize: 13,
        marginLeft: 4
    }
}
import React, { Component, ChangeEvent } from "react";
import DialogPromptView from "../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";
import DialogTimePickerView from "../../../../components/DialogBase/components/DialogTimePicker/DialogTimePickerView";
import DialogMultilineTextInputView from "../../../../components/DialogBase/components/DialogMultilineTextInput/DialogMultilineTextInputView";
import DialogBaseView from "../../../../components/DialogBase/DialogBaseView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import ConfirmAvailabilityOfficeDialogViewProps from './ConfirmAvailabilityOfficeDialogViewProps';
import ConfirmAvailabilityOfficeDialogViewState from './ConfirmAvailabilityOfficeDialogViewState';
import ConfirmAvailabilityOfficeDialogViewStyle from "./ConfirmAvailabilityOfficeDialogViewStyle";
import { ReadableDate } from "../../../../../../components/readableDate/ReadableDate";

export default class ConfirmAvailabilityOfficeDialogView extends Component<ConfirmAvailabilityOfficeDialogViewProps, ConfirmAvailabilityOfficeDialogViewState> {

    // ATTRIBUTES
    style = new ConfirmAvailabilityOfficeDialogViewStyle();

    state = {
        selectedTime: '',
        comment: ''
    }


    // HANDLERS
    onSubmit = (done: () => void) => {
        this.props.onSubmit(this.state, done);
    }

    onTimeChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            selectedTime: e.target.value
        });
    }

    onCommentChange = (comment: string) => {
        this.setState({ comment });
    }

    renderExtraDateSuggestion = () => {
        const { checkTimeEnd, checkTimeOfDay, checkTimeStart } = this.props;

        if (checkTimeEnd !== null && checkTimeEnd && checkTimeOfDay && checkTimeOfDay === "2") {
            return (
                <>
                    <TranslationView>
                        SuggestedCheckTimeBetweenBefore
                    </TranslationView>
                    {checkTimeStart}
                    <TranslationView>
                        SuggestedCheckTimeBetweenAfter
                    </TranslationView>
                    {checkTimeEnd}
                </>
            )
        }

        if (checkTimeStart !== null && checkTimeStart && checkTimeOfDay && checkTimeOfDay === "1") {
            return (
                <>
                    <TranslationView>
                        SuggestedCheckTimeHour
                    </TranslationView>
                    {checkTimeStart}
                </>
            )
        }

        if (checkTimeOfDay !== null && checkTimeOfDay && checkTimeOfDay === "0") {
            return (
                <TranslationView>
                    SuggestedCheckTimeSingle
                </TranslationView>
            )
        }

        return (
            <>
            </>
        )
    }

    // RENDER
    render() {
        const { error, checkDate } = this.props;

        return (
            <DialogBaseView title='bookAppointment' subtitle='inCabinetCheck' onSubmit={this.onSubmit} error={error}>
                <DialogPromptView>
                    <TranslationView style={{ display: "block" }}>
                        doctorGreeting
                    </TranslationView>
                    <div style={{ marginTop: 10 }}>
                        <TranslationView>
                            whenAreYouAvailableStartOffice
                        </TranslationView>
                        <span style={this.style.date}>
                            <ReadableDate date={Date.parse(checkDate)} />
                            {this.renderExtraDateSuggestion()}
                        </span>
                        <TranslationView>
                            whenAreYouAvailableEndOffice
                        </TranslationView>
                    </div>
                </DialogPromptView>

                <div style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
                    <DialogTimePickerView
                        error={this.props.error !== undefined}
                        value={this.state.selectedTime}
                        onChange={this.onTimeChange} />
                </div>

                <DialogMultilineTextInputView
                    rows={4}
                    placeholder='addComment'
                    onChange={this.onCommentChange} />
            </DialogBaseView>
        );
    }
}
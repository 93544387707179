import * as React from 'react';
import TranslationView from '../translation/TranslationView';
import { formatDateToReadableString } from '../../../lib/Dates';

export const ReadableDate = (props: any) => {
    return (
        <span>
            <TranslationView>
                {`${formatDateToReadableString(props.date, "eeee")}`}
            </TranslationView>
            <span style={{ margin: 4 }}>
                {formatDateToReadableString(props.date, "d")}
            </span>
            <TranslationView>
                {formatDateToReadableString(props.date, "MMMM")}
            </TranslationView>
        </span>
    );
}
import React, { Component } from "react";
import DialogPromptStyle from "./DialogPromptViewStyle";
import DialogPromptViewProps from "./DialogPromptViewProps";
import { isMobile } from "react-device-detect";

export default class DialogPromptView extends Component<DialogPromptViewProps> {

    private _style = new DialogPromptStyle();

    render() {
        return (
            <span style={isMobile  ? { ...this._style.mobilePrompt, ...this.props.style } : { ...this._style.prompt, ...this.props.style }}>
                {this.props.children}
            </span>
        );
    }
}
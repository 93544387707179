import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import InfoDialogTitleViewStyle from "./InfoDialogTitleViewStyle";

export default class InfoDialogTitleView extends Component {

    private _style = new InfoDialogTitleViewStyle();

    render() {
        return (
            <span style={isMobile ? this._style.mobileTitle : this._style.title}>
                {this.props.children}
            </span>
        );
    }
}
import React, { Component } from "react";
import InfoDialogTitleView from "../../../../components/InfoDialogBase/InfoDialogTitle/InfoDialogTitleView";
import InfoDialogBaseView from "../../../../components/InfoDialogBase/InfoDialogBaseView";
import InfoDialogSubtitleView from "../../../../components/InfoDialogBase/InfoDialogSubtitle/InfoDialogSubtitleView";
import InfoDialogTextView from "../../../../components/InfoDialogBase/InfoDialogText/InfoDialogTextView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import { ReadableDate } from "../../../../../../components/readableDate/ReadableDate";
import CheckConfirmationViewProps from "./CheckConfirmationViewProps";

export default class CheckConfirmationView extends Component<CheckConfirmationViewProps> {

    render() {
        const { checkDate, checkTime } = this.props;

        return (
            <InfoDialogBaseView>
                <InfoDialogTitleView>
                    <TranslationView style={{ fontWeight: 300 }}>
                        confirmationSent
                    </TranslationView>
                </InfoDialogTitleView>

                <InfoDialogSubtitleView>
                    <span style={{ fontWeight: 500 }}>
                        <ReadableDate date={Date.parse(checkDate)} />
                        <TranslationView>
                            at
                        </TranslationView>
                        <span>
                            {checkTime}
                        </span>
                    </span>
                </InfoDialogSubtitleView>

                <InfoDialogTextView>
                    <TranslationView style={{ fontWeight: 300 }}>
                        moreInfoAboutCheck
                    </TranslationView>
                </InfoDialogTextView>
            </InfoDialogBaseView>
        );
    }
}
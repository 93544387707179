import axios, { AxiosPromise, AxiosInstance } from "axios";
import AvailabilityDTO from "../models/AvailabilityDTO";
import CheckEditDTO from "../models/CheckEditDTO";
import CheckCancelDTO from "../models/CheckCancelDTO";
import GSMProblemDTO from "../models/GSMProblemDTO";

type SafeResponse = {
    error?: string,
    data?: any
}

export default class Service {
    static readonly BASE_URL = `${process.env.REACT_APP_RESOURCE_SERVER}/v1/api/checks`;

    private _http: AxiosInstance;

    constructor() {
        this._http = axios.create({
            baseURL: Service.BASE_URL,
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    private _transformResponse = async (promise: AxiosPromise, onCatchErrorMessage: string): Promise<SafeResponse> => {
        try {
            const response = await promise;

            if (response && response.data) {
                return {
                    error: undefined,
                    data: response.data
                };
            }

            else {
                return {
                    error: 'Unable to receive response/data from server',
                    data: undefined
                };
            }
        } catch (error) {
            const completeError = `${onCatchErrorMessage} - ${error}`;
            console.error(completeError);
            return {
                error: completeError,
                data: undefined
            };
        }
    }

    // #region AVAILABILITY
    confirmAvailability = (checkId: string, data: AvailabilityDTO) => {
        return this._transformResponse(
            this._http.post(`/${checkId}/forms/confirm-availability`, data),
            `Error confirming availability for check ${checkId}`
        );
    }

    denyAvailability = (checkId: string, data: AvailabilityDTO) => {
        return this._transformResponse(
            this._http.delete(`/${checkId}/forms/deny-availability`, { data }),
            `Error denying availability for check ${checkId}`
        );
    }
    // #endregion

    // #region EDIT CHECK
    editCheck = (checkId: string, data: CheckEditDTO) => {
        return this._transformResponse(
            this._http.put(`/${checkId}/forms/edit-check`, data),
            `Error editing check ${checkId}`
        );
    }
    // #endregion

    // #region CANCEL CHECK
    cancelCheck = (checkId: string, data: CheckCancelDTO) => {
        return this._transformResponse(
            this._http.delete(`/${checkId}/forms/cancel-check`, { data }),
            `Error canceling check ${checkId}`
        );
    }
    // #endregion

    // #region RELAUNCH CHECK
    relaunchCheck = (checkId: string, data: CheckCancelDTO) => {
        return this._transformResponse(
            this._http.post(`/${checkId}/forms/relaunch-check`, data),
            `Error relaunching check ${checkId}`
        );
    }
    // #endregion

    // #region GSM PROBLEM
    reportGSMProblem = (checkId: string, data: GSMProblemDTO) => {
        return this._transformResponse(
            this._http.post(`/${checkId}/forms/gsm-problem`, data),
            `Error reporting GSM problem for check ${checkId}`
        );
    }
    // #endregion

    // #region SWITCH TELECHECK
    switchTelecheck = (checkId: string, data: CheckCancelDTO) => {
        return this._transformResponse(
            this._http.put(`/${checkId}/forms/switch-telecheck`, data),
            `Error switch Telecheck for check ${checkId}`
        );
    };
    // #endregion
}
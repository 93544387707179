import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import GSMProblemDialogView from "./components/GSMProblemDialog/GSMProblemDialogView";
import GSMProblemDialogViewState from "./components/GSMProblemDialog/GSMProblemDialogViewState";
import GSMProblemViewState from "./GSMProblemViewState";
import Service from "../../../../../network/Service";
import Application from "../../../../Application";

export default class GSMProblemView extends Component<SharedViewProps, GSMProblemViewState> {

    service = new Service();

    state = {
        errors: {},
        queryParams: new URLSearchParams(this.props.routeProps.location.search),
        hasPhoneNumber: true
    };

    render() {
        return (
            <GSMProblemDialogView errors={this.state.errors} onSubmit={this.onSubmit} resetErrors={this.resetErrors} hasPhoneNumber={this.state.hasPhoneNumber} onHasPhoneNumberChange={this.onHasPhoneNumberChange} onDisable={this.onDisable}/>
        );
    }

    onSubmit = async (formData: GSMProblemDialogViewState, done: () => void) => {
        this.setState({ errors: {} });

        const superiorId = this.state.queryParams.get('superiorId');
        const checkId = this.state.queryParams.get('checkId');
        const { gsmNumber, selectedAction } = formData;

        if (!superiorId || !checkId) {
            this.setState(prev => {
                prev.errors['form'] = 'genericError';
                return prev;
            });
            done();
            return;
        } else if (this.state.hasPhoneNumber && !gsmNumber){
            done();
            this.setState(prev => {
                prev.errors['gsmNumber'] = 'errorRequiredField'
                return prev;
            });
        } else if(!this.state.hasPhoneNumber && selectedAction === undefined){
            done();
            this.setState(prev => {
                prev.errors['action'] = 'errorRequiredField'
                return prev;
            });
        }
        
        // Technically valid state as a value has been entered in only one of the fields
        else {
            const { error } = await this.service.reportGSMProblem(checkId, {
                superiorId: superiorId,
                gsmNumber: gsmNumber,
                action: selectedAction
            });

            if (!error) {
                this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                    origin: 'gsm-problem'
                });
            }

            else {
                this.setState(prev => {
                    prev.errors['form'] = 'genericError';
                    return prev;
                });
                done();
            }
        }
    }

    resetErrors = () => {
        this.setState({ errors: {} });
    }

    onHasPhoneNumberChange = (hasPhoneNumberChange: boolean) => {
        this.setState({ hasPhoneNumber : hasPhoneNumberChange });
    }

    onDisable = (formData: GSMProblemDialogViewState) => {
        const { gsmNumber, selectedAction } = formData;

        if (this.state.hasPhoneNumber && !gsmNumber){
            return true;
        } else if(!this.state.hasPhoneNumber && selectedAction === undefined){
            return true;
        }
        return false;
    }
}
import { CSSProperties } from "react";

export default class DialogErrorTextViewStyle {

    text: CSSProperties = {
        color: '#EC5467',
        fontSize: 15,
        fontWeight: 300,
        fontFamily: 'Roboto'
    }
}
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import InfoDialogSubtitleViewStyle from "./InfoDialogSubtitleViewStyle";

export default class InfoDialogSubtitleView extends Component {

    private _style = new InfoDialogSubtitleViewStyle();

    render() {
        return(
            <span style={isMobile ? this._style.mobileSubtitle : this._style.subtitle}>
                {this.props.children}
            </span>
        );
    }
}
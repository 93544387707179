import React, { Component, ChangeEvent } from "react";
import DialogTextInputViewProps from "./DialogTextInputViewProps";
import { TextField, FormControl, FormHelperText } from "@material-ui/core";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DialogMultilineTextInputViewStyle from "../DialogMultilineTextInput/DialogMultilineTextInputViewStyle";

export default class DialogTextInputView extends Component<DialogTextInputViewProps> {

    private _style = new DialogMultilineTextInputViewStyle();

    render() {
        const { style, placeholder, error } = this.props;

        return (
            <FormControl style={{ ...this._style.textField, ...style }} error={error !== undefined}>
                <TextField
                    placeholder={placeholder && TranslationView.translate(placeholder)}
                    onChange={this.onChange} />
                    
                {error &&
                    <FormHelperText>
                        <TranslationView>
                            {error}
                        </TranslationView>
                    </FormHelperText>
                }
            </FormControl>
        );
    }

    onChange = (e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange && this.props.onChange(e.target.value);
    }
}
import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import CheckConfirmationView from "./components/CheckConfirmation/CheckConfirmationView";
import NoAvailabilityView from "./components/NoAvailability/NoAvailabilityView";
import TranslationView from "../../../../components/translation/TranslationView";
import CheckEditConfirmationView from "./components/CheckEditConfirmation/CheckEditConfirmationView";
import CheckCancellationConfirmationView from "./components/CheckCancellationConfirmation/CheckCancellationConfirmationView";
import GSMProblemConfirmationView from "./components/GSMProblemConfirmation/GSMProblemConfirmationView";
import CheckRelaunchConfirmationView from "./components/CheckRelaunchConfirmation";
import TelecheckSwitchConfirmationView from "./components/TelecheckSwitchConfirmation";

export default class SubmitSuccessView extends Component<SharedViewProps> {
  render() {
    let { state } = this.props.routeProps.location;

    switch (state.origin) {
      case "confirm-availability":
        if (state.checkDate) {
          return (
            <CheckConfirmationView
              checkDate={state.checkDate}
              checkTime={state.checkTime}
            />
          );
        } else {
          return <TranslationView>genericError</TranslationView>;
        }
      case "deny-availability":
        return <NoAvailabilityView />;
      case "edit-check":
        return <CheckEditConfirmationView />;
      case "cancel-check":
        return <CheckCancellationConfirmationView />;
      case "relaunch-check":
        return <CheckRelaunchConfirmationView />;
      case "gsm-problem":
        return <GSMProblemConfirmationView />;
      case "switch-telecheck":
        return <TelecheckSwitchConfirmationView />;
      default:
        return <TranslationView>genericError</TranslationView>;
    }
  }
}

import React, { Component } from "react";
import InfoDialogFooterView from "./InfoDialogFooter/InfoDialogFooterView";
import InfoDialogBaseViewStyle from "./InfoDialogBaseViewStyle";
import { isMobile } from "react-device-detect";

export default class InfoDialogBaseView extends Component {

    private _style = new InfoDialogBaseViewStyle();

    render() {
        return (
            <div style={isMobile ? this._style.mobileContainer : this._style.container}>

                <div style={this._style.content}>
                    {this.props.children}
                </div>

                <InfoDialogFooterView />
            </div>
        );
    }
}
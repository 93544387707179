import { CSSProperties } from "react";
import FontWeight from "../../../../../../constants/FontWeight";

export default class InfoDialogTextViewStyle {

    text: CSSProperties = {
        fontFamily: 'Asap',
        fontWeight: FontWeight.REGULAR,
        fontSize: 21,
        lineHeight: '28px',
        color: '#fff',
        textAlign: "center"
    }

    mobileText: CSSProperties = {
        fontFamily: 'Roboto',
        fontWeight: FontWeight.REGULAR,
        fontSize: 15,
        lineHeight: '17.58px',
        color: '#fff',
        textAlign: "center"
    }
}
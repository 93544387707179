import * as React from 'react';
import FooterViewState from './FooterViewState';
import FooterViewProps from './FooterViewProps';
import FooterViewStyle from './FooterViewStyle';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import TranslationView from '../translation/TranslationView';
import { isMobile } from 'react-device-detect';

const pjson = require('../../../../package.json');

export default class FooterView extends React.Component<FooterViewProps, FooterViewState>
{
	private _style = new FooterViewStyle();

	/* CONSTRUCTOR */
	constructor(props: FooterViewProps) {
		super(props);

		this.state = { openDialog: false, count: 0 };

		// Bindings
		this.handleClose = this.handleClose.bind(this);
		this.incrementCount = this.incrementCount.bind(this);
	}

	/* HANDLERS */
	incrementCount() {
		this.setState({
			count: this.state.count + 1
		}, () => {
			if (this.state.count % 10 === 0) {
				this.setState({ openDialog: true });
			}
		});
	}

	handleClose() {
		this.setState({ openDialog: false });
	}

	handlePrivacyPolicy() {
		window.open("https://privacy.medicheck.io", "_blank");
	}

	/* RENDERING */
	render() {
		return (
			<div style={isMobile ? this._style.mobileMadeByMediCheck : this._style.madeByMediCheck} onClick={this.incrementCount}>
				<TranslationView>
					madebymedicheck
				</TranslationView>
				<TranslationView style={this._style.privacyPolicy} onClick={this.handlePrivacyPolicy}>
					privacyPolicy
				</TranslationView>
				<Dialog
					fullScreen={false}
					open={this.state.openDialog}
					aria-labelledby="responsive-dialog-title">

					<DialogTitle id="responsive-dialog-title">Build Information</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<React.Fragment>
								<div>
									<b>Version</b>
									<br />
									{pjson.version}
								</div>
								<br />
								<div>
									<b>Resource Server URL</b>
									<br />
									{process.env.REACT_APP_RESOURCE_SERVER}
								</div>
								<br />
								<div>
									<b>IAM Server URL</b>
									<br />
									{process.env.REACT_APP_IAM_SERVER}
								</div>
								<br />
							</React.Fragment>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary" autoFocus>
							<TranslationView>
								understood
							</TranslationView>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
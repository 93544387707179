import React, { Component } from "react";
import DialogBaseView from '../../../../components/DialogBase/DialogBaseView';
import DialogPromptView from "../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import DialogMultilineTextInputView from "../../../../components/DialogBase/components/DialogMultilineTextInput/DialogMultilineTextInputView";
import TelecheckSwitchDialogViewProps from "./TelecheckSwitchDialogViewProps";
import TelecheckSwitchDialogViewState from "./TelecheckSwitchDialogViewState";

export default class TelecheckSwitchDialogView extends Component<TelecheckSwitchDialogViewProps, TelecheckSwitchDialogViewState> {

    state = {
        comment :  ''
    };

    render() {
        return (
            <DialogBaseView title='switchTelecheck' subtitle='switchTelecheckSubtitle' onSubmit={this.onSubmit} error={this.props.error}>
                <DialogPromptView>
                    <TranslationView>
                    pressSendToSwitchTelecheck
                    </TranslationView>
                </DialogPromptView>

                <DialogMultilineTextInputView onChange={this.onChange} rows={4} placeholder='addComment' />
            </DialogBaseView>
        );  
    }

    onSubmit = (done : () => void) => {
        this.props.onSubmit(this.state, done);
    }

    onChange = (comment : string) => {
        this.setState({
            comment : comment
        });
    }
}
import * as React from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import FooterView from './components/footer/FooterView';
import ApplicationStyle from './ApplicationStyle';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import FormsView from './modules/forms/FormsView';
import { isMobile } from 'react-device-detect';

export default class Application extends React.Component {
    private _style = new ApplicationStyle();
    private _history = createBrowserHistory();
    public static language = "";

    /* RENDER */
    render() {
        return (
            <Router history={this._history}>
                <ThemeProvider theme={this._style.theme}>
                    <div style={isMobile ? this._style.mobileShell : this._style.shell}>
                        <Switch>
                            <Route path='/:language/forms' render={routeProps => <FormsView routeProps={routeProps} />} />
                            <Route path="/:language/" render={routeProps => <Redirect to={`/${routeProps.match.params.language}/forms`} />} />
                        </Switch>

                        <FooterView />
                    </div>
                </ThemeProvider>
            </Router>
        );
    }
}
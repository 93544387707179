import React, { Component } from "react";
import InfoDialogBaseView from "../../../../components/InfoDialogBase/InfoDialogBaseView";
import InfoDialogTitleView from "../../../../components/InfoDialogBase/InfoDialogTitle/InfoDialogTitleView";
import InfoDialogSubtitleView from "../../../../components/InfoDialogBase/InfoDialogSubtitle/InfoDialogSubtitleView";
import TranslationView from "../../../../../../components/translation/TranslationView";

export default class CheckRelaunchConfirmationView extends Component {

    render() {
        return (
            <InfoDialogBaseView>
                <div style={{marginTop: 50}}>
                <InfoDialogTitleView>
                    <TranslationView style={{ fontWeight: 300 }}>
                        requestConfirmation
                    </TranslationView>
                </InfoDialogTitleView>
                </div>
                <div style={{marginTop: 10}}>
                <InfoDialogSubtitleView>
                    <TranslationView style={{ fontWeight: 400 }}>
                        requestSent
                    </TranslationView>
                </InfoDialogSubtitleView>
                </div>
                <div style={{textAlign: 'center', padding: "30px"}}>
                    <InfoDialogTitleView>
                        <TranslationView style={{ fontWeight: 300 }}>
                            checkRelaunchConfirmation
                        </TranslationView>
                    </InfoDialogTitleView>
                </div>
            </InfoDialogBaseView>
        );
    }
}
import React, { Component } from "react";
import SharedViewProps from "../../../../shared/SharedViewProps";
import Service from "../../../../../network/Service";
import DenyAvailabilityViewState from "./DenyAvailabilityViewState";
import DenyAvailabilityDialogView from "./components/DenyAvailabilityDialog/DenyAvailabilityDialogView";
import DenyAvailablilityDialogViewState from "./components/DenyAvailabilityDialog/DenyAvailabilityDialogViewState";
import Application from "../../../../Application";

export default class DenyAvailabilityView extends Component<SharedViewProps, DenyAvailabilityViewState> {

    service = new Service();
    state = {
        error: undefined,
        queryParams: new URLSearchParams(this.props.routeProps.location.search)
    }

    render() {
        const checkDate = this.state.queryParams.get('checkDate');

        if (checkDate) {
            return (
                <DenyAvailabilityDialogView error={this.state.error} checkDate={checkDate} onSubmit={this.onSubmit} />
            );
        }

        else {
            return (
                <span>Erreur</span>
            );
        }
    }

    onSubmit = async (formData: DenyAvailablilityDialogViewState, done: () => void) => {
        const checkId = this.state.queryParams.get('checkId');
        const doctorId = this.state.queryParams.get('doctorId');
        const checkDate = this.state.queryParams.get('checkDate');

        if (checkId && doctorId && checkDate) {
            const { error } = await this.service.denyAvailability(checkId, {
                checkDate: checkDate,
                doctorId: doctorId,
                doctorComment: formData.comment
            });

            if (!error) {
                this.props.routeProps.history.push(`/${Application.language}/forms/submit-success`, {
                    origin: 'deny-availability'
                });
            }

            else {
                this.props.routeProps.history.push(`/${Application.language}/forms/already-booked-check`, {
                    origin: 'deny-availability'
                });
            }
        }

        done();
    }
}
import * as React from 'react';
import TranslationViewProps from './TranslationViewProps';
import Application from '../../Application';

export default class TranslationView extends React.Component<TranslationViewProps>
{
    /* ATTRIBUTES */
    static translations: any;

    /* CONSTRUCTOR */
    constructor(props: TranslationViewProps) {
        super(props);

        if (!TranslationView.translations) {
            TranslationView.translations = require('../../translations/translations.json');
        }
    }

    /* METHODS */
    static translate(key: string, language: string = Application.language, upperCase: boolean = false) {
        try {
            let word: string = "";

            word = TranslationView.translations[key][language];

            if (word === "") {
                return `[${key}]`;
            }

            if (upperCase) {
                return word.toUpperCase();
            }

            return word;
        }

        catch (ex) {
            return `[${key}]`;
        }
    }

    /* RENDERING */
    render() {
        let language = this.props.language;
        const { children, upperCase, style, onClick } = this.props

        if (!this.props.language) {
            language = Application.language;
        }

        return (
            <span onClick={onClick} style={style}>
                {TranslationView.translate(children, language, upperCase)}
            </span>
        );
    }
}
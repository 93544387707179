import { CSSProperties } from "react";

export default class DialogPromptViewStyle {
  prompt: CSSProperties = {
    fontSize: 17,
    lineHeight: "25px",
    color: "#323A40",
    fontWeight: 300,
    marginBottom: 0,
  };

  mobilePrompt: CSSProperties = {
    fontSize: 15,
    lineHeight: "17.58px",
    color: "#323A40",
    fontWeight: 300,
    marginBottom: 0,
    fontFamily: "Roboto",
  };
}

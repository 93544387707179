import React, { Component } from "react";
import { MenuItem } from "@material-ui/core";
import GSMProblemDialogActionViewProps from "./GSMProblemDialogActionViewProps";
import GSMProblemAction from "../../../../../../../../../models/constants/GSMProblemAction";
import { getKeys } from "../../../../../../../../../lib/Enums";
import DialogPromptView from "../../../../../../components/DialogBase/components/DialogPrompt/DialogPromptView";
import TranslationView from "../../../../../../../../components/translation/TranslationView";
import DialogSelectView from "../../../../../../components/DialogBase/components/DialogSelect/DialogSelectView";

export default class GSMProblemDialogActionView extends Component<GSMProblemDialogActionViewProps> {

    render() {
        const { errors, selectedAction, onActionChange } = this.props;

        return (
            <div>
                <DialogPromptView style={{ marginBottom: 20 }}>
                    <TranslationView>
                        noOtherGSMNumber
                    </TranslationView>
                </DialogPromptView>
                <DialogSelectView placeholder='chooseAnOption' value={selectedAction} error={errors.action} onChange={onActionChange}>
                    {getKeys(GSMProblemAction).map((action, index) => (
                        <MenuItem key={index} value={index}>
                            <TranslationView>
                                {action}
                            </TranslationView>
                        </MenuItem>
                    ))}
                </DialogSelectView>
            </div>
        );
    }
}
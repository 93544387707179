import { CSSProperties } from "react";

export default class DialogFooterViewStyle {
    
    dialogFooterButton : CSSProperties = {
        display : "flex",
        alignItems : "center",
        padding : 30,
        paddingTop: 10,
        width: "100%",
        flexDirection: "row",
    }

    dialogFooterButtonMobile : CSSProperties = {
        display : "flex",
        alignItems : "center",
        padding : 30,
        paddingTop: 10,
        width: "100%",
        flexDirection: "column",
        gap: '10px'
    }

    dialogFooter : CSSProperties = {
        display : "flex",
        flexDirection: "column",
        alignItems : "center",
        justifyContent : 'flex-end',
        padding : "0px 30px 0px 30px ",
        paddingTop: 0,
        width: "full"
    }
}
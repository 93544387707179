import React, { Component } from "react";
import InfoDialogTitleView from "../../components/InfoDialogBase/InfoDialogTitle/InfoDialogTitleView";
import InfoDialogBaseView from "../../components/InfoDialogBase/InfoDialogBaseView";
import InfoDialogSubtitleView from "../../components/InfoDialogBase/InfoDialogSubtitle/InfoDialogSubtitleView";
import InfoDialogTextView from "../../components/InfoDialogBase/InfoDialogText/InfoDialogTextView";
import SharedViewProps from "../../../../shared/SharedViewProps";
import TranslationView from "../../../../components/translation/TranslationView";

export default class AlreadyBookedCheckView extends Component<SharedViewProps> {
  render() {
    return (
      <InfoDialogBaseView>
        <InfoDialogTitleView>
          <TranslationView style={{ fontWeight: 300 }}>sorry</TranslationView>
        </InfoDialogTitleView>

        <InfoDialogSubtitleView>
          <TranslationView style={{ fontWeight: 500 }}>
            checkAlreadyScheduled
          </TranslationView>
        </InfoDialogSubtitleView>

        <InfoDialogTextView>
          <TranslationView style={{ fontWeight: 300 }}>
            checkAlreadyScheduledWithOtherDoctor
          </TranslationView>
        </InfoDialogTextView>
      </InfoDialogBaseView>
    );
  }
}

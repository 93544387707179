import { CSSProperties } from "react";

export default class DialogSelectViewStyle {
  control: CSSProperties = {
    marginBottom: 20,
  };

  placeholder: CSSProperties = {
    color: "#323A40",
    opacity: 0.4,
    fontSize: 18,
  };
}

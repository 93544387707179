import React, { Component } from "react";
import DialogFooterViewProps from './DialogFooterViewProps';
import DialogFooterViewStyle from './DialogFooterViewStyle';
import ButtonView from "../../../../../../components/button/ButtonView";
import DialogFooterViewState from './DialogFooterViewState';
import DialogErrorTextView from "../DialogErrorText/DialogErrorTextView";
import TranslationView from "../../../../../../components/translation/TranslationView";
import { isMobile } from "react-device-detect";

export default class DialogFooterView extends Component<DialogFooterViewProps, DialogFooterViewState> {

    private _style = new DialogFooterViewStyle();

    state = {
        busy: false
    };

    renderButtonMobile(){
        return(
            <div style={{...this._style.dialogFooterButtonMobile, justifyContent: this.props.hasLeftButton ? 'space-between' : 'flex-end'}}>
                <ButtonView hasWidthFull={this.props.hasLeftButton ? this.props.hasLeftButton : false} onClick={this.onSubmit} isBusy={this.state.busy} disabled={this.props.disabled}>
                    send
                </ButtonView>
                {this.props.hasLeftButton && this.props.titleLeftButton &&
                    <ButtonView hasWidthFull inverted={true} onClick={this.onSubmitLeftButton} isBusy={this.state.busy}>
                        {this.props.titleLeftButton}
                    </ButtonView>
                }
            </div>
        );
    }

    render() {
        return (
            <div style={this._style.dialogFooter}>

                <DialogErrorTextView>
                    {this.props.error &&

                        <TranslationView>
                            {this.props.error}
                        </TranslationView>
                    }

                </DialogErrorTextView>
                {isMobile && this.props.hasLeftButton ? (
                    <>
                        {this.renderButtonMobile()}
                    </>
                ) :(
                    <div style={{...this._style.dialogFooterButton, justifyContent: this.props.hasLeftButton ? 'space-between' : 'flex-end'}}>
                        {this.props.hasLeftButton && this.props.titleLeftButton &&
                            <ButtonView inverted={true} onClick={this.onSubmitLeftButton} isBusy={this.state.busy}>
                                {this.props.titleLeftButton}
                            </ButtonView>
                        }

                        <ButtonView width={100} onClick={this.onSubmit} isBusy={this.state.busy} disabled={this.props.disabled}>
                            send
                        </ButtonView>
                    </div>
                )}
            </div>
        );
    }

    onSubmit = () => {
        this.setState({
            busy: true
        });

        this.props.onSubmit(() => {
            this.setState({
                busy: false
            });
        });
    }

    onSubmitLeftButton = () => {
        this.setState({
            busy: true
        });

        if(this.props.onSubmitLeftButton){
            this.props.onSubmitLeftButton(() => {
                this.setState({
                    busy: false
                });
            });
        }
    }
    
}
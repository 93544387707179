import { CSSProperties } from "react";
import FontWeight from '../../../../../../constants/FontWeight';

export default class InfoDialogSubtitleViewStyle {

    subtitle: CSSProperties = {
        fontFamily: 'Asap',
        fontWeight: FontWeight.MEDIUM,
        fontSize: 28,
        color: '#fff',
        marginBottom: 20
    }

    mobileSubtitle: CSSProperties = {
        fontFamily: 'Asap',
        fontWeight: FontWeight.MEDIUM,
        fontSize: 18,
        color: '#fff',
        marginBottom: 20,
        lineHeight: "20.63px"
    }
}